import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
import Layout  from "../components/Layout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const About = ({
  data: {
    allContentfulAbout: { nodes },
  },
}) => {
const {title, stack, info, image} = nodes[0]
  
  
  return (
    <Layout>
      <Seo title="About" description="About" />
      <section className="about-page">
     
        <div className="section-center about-center">
        <GatsbyImage 
            image={image.gatsbyImageData} 
            alt='hero' 
            className="about-img"
            
          />
       
          <article className="about-text">
            <Title title={title} />
            <p>{info.internal.content}</p>
            <div className="about-stack">
              {
                stack.tags.map((item, index) => {
                  return (
                    <span key={item.index}>{item}</span>
                  )
                })
              }
              
            </div>

          </article>
        </div>
      </section>
      
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulAbout {
      nodes {
        title
        info {
          internal {
            content
          }
        }
        image {
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, height: 400, width: 400)
        }
        stack {
          tags
        }
      }
    }
  }
`

export default About
